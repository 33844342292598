import React from "react"
import { Link } from "gatsby"
import {
  Segment,
  Container,
  Icon,
  Header,
  Button,
  Grid,
  Card,
} from "semantic-ui-react"

import Layout from "../components/layout"
import Image2 from "../components/image2"
import SEO from "../components/seo"
import EmbedYoutube from "../components/EmbedYoutube"

const IndexPage = () => (
  <Layout>
    <SEO/>

    <Segment
      inverted
      textAlign="center"
      style={{ minHeight: 500, padding: "1em 0em" }}
      vertical
    >
      <Container style={{ paddingBottom: "5em"}}>
        {/*
        <div style={{ maxWidth: `150px`, marginBottom: `1.45rem` }}>
          <Image />
        </div>
        */}
        <Header
          as="h1"
          content="Teaching Programming?"
          inverted
          style={{
            fontSize: "4em",
            fontWeight: "normal",
            marginBottom: 0,
            marginTop: "1.3em",
          }}
        />
        <Header
          as="h2"
          content="Turn Misconceptions into Learning Opportunities!"
          inverted
          style={{
            fontSize: "1.7em",
            fontWeight: "normal",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        />
        <EmbedYoutube
          id={"92Mfh3GI98Y"}
        />
        <Card.Group 
          itemsPerRow={3}
          style={{
            marginTop: "2em",
          }}
        >
          <Card as={Link} to='/misconceptions/Java/'>
            <Image2
                className="ui image"
                src="Code-Java.png"
              />
            <Button color="red" size="huge">
              Java <span style={{fontSize: 'small'}}></span>
              <Icon name="right arrow" />
            </Button>
          </Card>
          <Card as={Link} to='/misconceptions/Python/'>
            <Image2
              className="ui image"
              src="Code-Python.png"
            />
            <Button color="red" size="huge">
              Python <span style={{fontSize: 'small'}}></span>
              <Icon name="right arrow" />
            </Button>
          </Card>
          <Card as={Link} to='/misconceptions/JavaScript/'>
            <Image2
              className="ui image"
              src="Code-JavaScript.png"
            />
            <Button color="red" size="huge">
              JavaScript <span style={{fontSize: 'small'}}>Preview</span>
              <Icon name="right arrow" />
            </Button>
          </Card>
        </Card.Group>
      </Container>
    </Segment>

    <Segment
      textAlign="center"
      vertical
    >
      <Container style={{ padding: "5em"}}>
        <Header
          as="h3"
          content="Explore Misconceptions by..."
          style={{
            fontSize: "2em",
            marginBottom: "1em",
          }}
        />
        <div>
          <Button color="red" size="huge" as={Link} to="/concepts/">
            <Icon name="lightbulb" />
            Concept
          </Button>
          <span style={{padding: '8px'}} />
          <Button color="red" size="huge" as={Link} to="/specs/">
            <Icon name="code" />
            Language Feature
          </Button>
          <span style={{padding: '8px'}} />
          <Button color="red" size="huge" as={Link} to="/notionalMachines/">
            <Icon name="settings" />
            Notional Machine
          </Button>
          <span style={{padding: '8px'}} />
          <Button color="red" size="huge" as={Link} to="/textbooks/">
            <Icon name="book" />
            Textbook
          </Button>
        </div>
      </Container>
    </Segment>

    <Segment style={{ padding: "6em 0em" }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4}>
            <Icon className="circular massive inverted red hand holding heart"/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Misconceptions are Valuable!
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              The "misconceptions" documented in this inventory
              often are conceptions students constructed in thoughtful and ingenious ways.
              A misconception in one programming language
              may well be an existing or possibly future 
              language feature in another language.
              We believe misconceptions are valuable,
              and we point out their value so you can build on them.
            </p>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4}>
            <Icon className="circular inverted massive yellow school"/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Rooted in Teaching Practice
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              This is a curated inventory of misconceptions students have while
              learning to program. This collection is based on observations in
              programming classrooms over the course of more than a decade.
            </p>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4}>
            <Icon className="circular inverted massive olive chalkboard teacher"/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Resource For Teachers
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              If you teach programming, this site is for you. You can easily
              find and navigate the misconceptions your students might have,
              discover possible causes, and learn about possible teaching
              interventions.
            </p>
            <p style={{ fontSize: "1.33em" }}>
              Navigate <Link to="/concepts/">by concept</Link>, or
              if your course uses a textbook we cross-referenced,
              find which <Link to="/textbooks/">misconceptions 
              your students may exhibit in the various chapters of your book</Link>.
            </p>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4}>
            <Icon className="circular inverted massive green seedling"/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Growing Inventory
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              The site currently contains only a small fraction of the
              misconceptions we collected. We will continuously add more. We
              have a lot of material, and we are gathering more in our research
              into Conceptual Change when Learning to Program.
            </p>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={2}></Grid.Column>
          <Grid.Column width={4}>
            <Icon className="circular inverted massive blue code"/>
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as="h3" style={{ fontSize: "2em" }}>
              Programming Languages
            </Header>
            <p style={{ fontSize: "1.33em" }}>
              We initially focused on Java 
              but are currently expanding the collection to Python and JavaScript.
            </p>
            <p style={{ fontSize: "1.33em" }}>
              To ensure that our collection rests on a solid foundation,
              we explicitly connect each misconception 
              to the relevant sections of the authoritative language specifications.
              This also allows you 
              to <Link to="/specs/">look up misconceptions for specific language features</Link>.
            </p>
          </Grid.Column>
          <Grid.Column width={2}></Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <Button size="huge" as={Link} to="/misconceptions">
              Check Them Out
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </Layout>
)

export default IndexPage
